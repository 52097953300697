<template>
  <div id="app" class="min-h-screen bg-primary-dark">
    <a class="hidden" href="/business-consumer"></a>
    <a class="hidden" href="/about"></a>
    <Header :isHeader="isHeader" />
    <router-view v-on:header="isHeader = $event" />
  </div>
</template>

<script>
import Header from "./components/Header.vue";
export default {
  name: "Home",
  data() {
    return {
      isHeader: true,
    };
  },
  metaInfo() {
    return {
      //title: "Non à la contrefaçon",
      //title: "No to counterfeit",
      title: "Neen tegen Namaak",
      meta: [
        {
          name: "description",
          /*content:
            "La NANAC a pour objectif de sensibiliser le plus grand nombre possible d'entreprises et de consommateurs aux dangers et aux inconvénients de la contrefaçon et de la piraterie. ",
          content:
            "NANAC aims to raise awareness among as many consumers and businesses as possible, alerting them to the dangers and disadvantages of counterfeiting and piracy. ",*/
          content:
            "NANAC heeft als doel om zo veel mogelijk bedrijven en consumenten bewust maken van de gevaren en nadelen van namaak en piraterij.",
        },
        {
          property: "keywords",
          content:
            "Namaak, Counterfeit,Nanac,Contrefaçon,Fake,Namaak producten,Fake products,Namaakgoederen,Contrefacon,Illegale handel ,Illicit trade ",
        },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  components: {
    Header,
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: 0.2s ease-in;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
