import Vue from "vue";
import VueI18n from "vue-i18n";
import nl from "@/localization/nl.json";
import fr from "@/localization/fr.json";
import en from "@/localization/en.json";

Vue.use(VueI18n);

export default new VueI18n({
  locale: "en",
  messages: { nl, fr, en },
  silentTranslationWarn: true,
});
