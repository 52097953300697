import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./localization";
import "./index.css";
import ArrowSVG from "./assets/img/arrow.svg";
import LogoFrSVG from "./assets/img/nanac-logos/logo-fr.svg";
import LogoEnSVG from "./assets/img/nanac-logos/logo-en.svg";
import LogoNlSVG from "./assets/img/nanac-logos/logo-nl.svg";
import VueMeta from "vue-meta";

Vue.use(VueMeta);
Vue.config.productionTip = false;
import VueCarousel from "vue-carousel";

Vue.component("ArrowSVG", ArrowSVG);
Vue.component("LogoFrSVG", LogoFrSVG);
Vue.component("LogoEnSVG", LogoEnSVG);
Vue.component("LogoNlSVG", LogoNlSVG);
Vue.use(VueCarousel);
Vue.use(i18n);
import PortalVue from "portal-vue";
Vue.use(PortalVue);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

const host = window.location.host;
// console.log("host", host);
switch (host) {
  case "www.notocounterfeit.be":
  // case "notocounterfeit.be.apache51.cloud.telenet.be":
    if (i18n.locale === "en") {
      console.log("same");
    }
    console.log("en");
    i18n.locale = "en";
    break;
  case "www.neentegennamaak.be":
  // case "neentegennamaak.be.apache51.cloud.telenet.be":
    if (i18n.locale === "nl") {
      console.log("same");
    }
    console.log("nl");
    i18n.locale = "nl";
    break;
  case "www.nonalacontrefacon.be":
  // case "nonalacontrefacon.be.apache51.cloud.telenet.be":
    if (host === "localhost:8082") {
      console.log("same");
    }
    console.log("fr");
    i18n.locale = "fr";
    break;
}
