<template>
  <div class="fixed top-0 left-0 z-50 w-full">
    <div
      class="
        flex
        items-center
        justify-between
        w-full
        px-4
        py-4
        transition-all
        bg-primary-dark bg-opacity-90
        md:py-5
      "
      :class="{ 'opacity-0 pointer-events-none': !isHeader && $route.name === 'Home' }"
    >
      <div class="container flex justify-between w-full mx-auto text-xl">
        <a href="/">
          <LogoFrSVG class="h-10" v-if="$i18n.locale === 'fr'" />
          <LogoEnSVG class="h-10" v-else-if="$i18n.locale === 'en'" />
          <LogoNlSVG class="h-10" v-else />
        </a>
        <div class="hidden space-x-16 text-white lg:flex">
          <button @click="changePage('consumer')">{{ $t("header.consumer") }}</button>
          <button @click="changePage('business')">{{ $t("header.business") }}</button>
          <button @click="changePage('service_provider')">
            {{ $t("header.service_provider") }}
          </button>
          <button @click="changePage('about')">{{ $t("header.about_us") }}</button>
        </div>
        <button class="flex text-white lg:hidden" @click="menu = !menu">
          <svg
            class="fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z" />
          </svg>
        </button>
        <div
          class="
            fixed
            top-0
            left-0
            w-full
            h-screen
            p-4
            text-left
            transition
            duration-500
            ease-in-out
            origin-bottom-right
            transform
            bg-white
          "
          :class="menu ? 'translate-x-0 ' : '-translate-x-full'"
        >
          <button class="flex justify-end w-full mb-4" @click="menu = false">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path
                d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"
              />
            </svg>
          </button>
          <a class="block w-full mb-8 text-2xl font-semibold text-center" href="/">
            {{ $t("header.home") }}
          </a>
          <button class="block w-full mb-8 text-2xl font-semibold" @click="changePage('consumer')">
            {{ $t("header.consumer") }}
          </button>
          <button class="block w-full mb-8 text-2xl font-semibold" @click="changePage('business')">
            {{ $t("header.business") }}
          </button>
          <button
            class="block w-full mb-8 text-2xl font-semibold"
            @click="changePage('service-provider')"
          >
            {{ $t("header.service_provider") }}
          </button>
          <button class="block w-full mb-8 text-2xl font-semibold" @click="changePage('about')">
            {{ $t("header.about_us") }}
          </button>
          <div class="relative flex items-center text-black">
            <button
              class="flex items-center h-full pl-4 mx-auto uppercase"
              @click="langOpen = !langOpen"
            >
              <span class="mr-1">{{ $i18n.locale }}</span>
              <ArrowSVG
                class="w-3 h-3 transition transform rotate-90 fill-current"
                :class="{ 'rotate-0': langOpen }"
              />
            </button>
            <div
              class="
                absolute
                mx-auto
                overflow-hidden
                text-center
                transition
                ease-in-out
                transform
                -translate-x-1/2
                rounded-sm
                opacity-0
                pointer-events-none
                left-1/2
                top-full
                text-primary-dark
              "
              :class="{
                'translate-y-0 pointer-events-auto opacity-100': langOpen,
                'translate-y-2': !langOpen,
              }"
            >
              <button
                @click="changeLocale('nl')"
                class="w-full px-10 py-2 text-center transition hover:text-primary hover:underline"
                :class="{ hidden: $i18n.locale === 'nl' }"
              >
                {{ $t("Nederlands") }}
              </button>
              <button
                @click="changeLocale('fr')"
                class="w-full px-10 py-2 text-center transition hover:text-primary hover:underline"
                :class="{ ' hidden': $i18n.locale === 'fr' }"
              >
                {{ $t("Français") }}
              </button>
              <button
                @click="changeLocale('en')"
                class="w-full px-10 py-2 text-center transition hover:text-primary hover:underline"
                :class="{ hidden: $i18n.locale === 'en' }"
              >
                {{ $t("English") }}
              </button>
            </div>
          </div>
        </div>
        <div class="relative items-center hidden text-right text-white lg:flex">
          <button class="flex items-center h-full pl-4 uppercase" @click="langOpen = !langOpen">
            <span class="mr-1">{{ $i18n.locale }}</span>
            <ArrowSVG
              class="w-3 h-3 transition transform rotate-90 fill-current"
              :class="{ 'rotate-0': langOpen }"
            /> 
          </button>
          <div
            class="
              absolute
              overflow-hidden
              text-left text-white
              transition
              ease-in-out
              transform
              -translate-x-1/2
              rounded-sm
              opacity-0
              pointer-events-none
              left-1/2
              top-full
              bg-primary-dark
            "
            :class="{
              'translate-y-0 pointer-events-auto opacity-100': langOpen,
              'translate-y-2': !langOpen,
            }"
          >
            <button
              @click="changeLocale('nl')"
              class="w-full px-10 py-2 text-left transition hover:text-primary hover:underline"
              :class="{ hidden: $i18n.locale === 'nl' }"
            >
              {{ $t("Nederlands") }}
            </button>
            <button
              @click="changeLocale('fr')"
              class="w-full px-10 py-2 transition hover:text-primary hover:underline"
              :class="{ ' hidden': $i18n.locale === 'fr' }"
            >
              {{ $t("Français") }}
            </button>
            <button
              @click="changeLocale('en')"
              class="w-full px-10 py-2 transition hover:text-primary hover:underline"
              :class="{ hidden: $i18n.locale === 'en' }"
            >
              {{ $t("English") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="$route.name === 'Home'"
      class="
        container
        fixed
        top-0
        flex
        justify-end
        py-4
        mt-2
        text-xl
        transform
        -translate-x-1/2
        left-1/2
        md:py-5
      "
      :class="{ 'opacity-0 pointer-events-none': isHeader }"
    >
      <div class="relative items-center hidden text-right text-white lg:flex">
        <button class="flex items-center h-full pl-4 uppercase" @click="langOpen = !langOpen">
          <span class="mr-1">{{ $i18n.locale }}</span>
          <ArrowSVG
            class="w-3 h-3 transition transform rotate-90 fill-current"
            :class="{ 'rotate-0': langOpen }"
          />
        </button>
        <div
          class="
            absolute
            overflow-hidden
            text-left text-white
            transition
            ease-in-out
            transform
            -translate-x-1/2
            rounded-sm
            opacity-0
            pointer-events-none
            left-1/2
            top-full
            bg-primary-dark
          "
          :class="{
            'translate-y-0 pointer-events-auto opacity-100': langOpen,
            'translate-y-2': !langOpen,
          }"
        >
          <button
            @click="changeLocale('nl')"
            class="w-full px-10 py-2 text-left transition hover:text-primary hover:underline"
            :class="{ hidden: $i18n.locale === 'nl' }"
          >
            {{ $t("Nederlands") }}
          </button>
          <button
            @click="changeLocale('fr')"
            class="w-full px-10 py-2 transition hover:text-primary hover:underline"
            :class="{ ' hidden': $i18n.locale === 'fr' }"
          >
            {{ $t("Français") }}
          </button>
          <button
            @click="changeLocale('en')"
            class="w-full px-10 py-2 transition hover:text-primary hover:underline"
            :class="{ hidden: $i18n.locale === 'en' }"
          >
            {{ $t("English") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {
    isHeader: Boolean,
  },
  data() {
    return {
      langOpen: false,
      menu: false,
    };
  },
  methods: {
    changeLocale(lang) {
      console.log(this.$route.fullPath);
      // window.location.href = `https://www.neentegennamaak.be${fullPath}`;
      const fullPath = this.$route.fullPath;
      const oldLocale = this.$i18n.locale;
      if (oldLocale === lang) {
        console.log("same");
      } else if (oldLocale !== lang && lang === "fr") {
        console.log("fr");
        window.location.href = `https://www.nonalacontrefacon.be${fullPath}`;
        // window.location.href = `https://nonalacontrefacon.be.apache51.cloud.telenet.be${fullPath}`;
      } else if (oldLocale !== lang && lang === "nl") {
        console.log("nl");
        window.location.href = `https://www.neentegennamaak.be${fullPath}`;
        // window.location.href = `https://neentegennamaak.be.apache51.cloud.telenet.be${fullPath}`;
      } else if ( oldLocale !== lang && lang === "en") {
        console.log("en");
        window.location.href = `https://www.notocounterfeit.be${fullPath}`;
        // window.location.href = `https://notocounterfeit.be.apache51.cloud.telenet.be${fullPath}`;
      }
      // this.lan gOpen = false;
      this.$i18n.locale = lang;
    }, 
    changePage(page) {
      this.menu = false;
      this.langOpen = false;
      if (page === "home") this.$router.push({ name: "Home" });
      else if (page === "about") this.$router.push({ name: "About" });
      else {
        this.$router.push({ name: "BusinessConsumer", hash: `#${page}` });
      }
    },
  },
};
</script>
